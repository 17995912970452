import {Component, OnInit} from '@angular/core';
import {IMarpp} from '../../../../interface/IMarpp';
import {MarppService} from '../../../../services/api/marppService.service';
import {SnackBarNotificationService} from '../../../../services/snack-bar-notification.service';
import {PrincipalAuthService} from '../../../../services/principal-auth.service';

@Component({
    selector: 'marco-marpps',
    templateUrl: './marpps.component.html',
    styleUrls: ['./marpps.component.scss']
})
export class MarppsComponent implements OnInit {

    public marppsMenuItems = [];

    constructor(
        private marppService: MarppService,
        private principalAuthService: PrincipalAuthService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {
    }

    ngOnInit() {
        this.loadUser();
    }

    private loadUser() {
        const sessionSubscription = this.principalAuthService.sessionSubscribe(activeSession => {
            if (activeSession && activeSession.user) {
                sessionSubscription.unsubscribe();
                this.startLoadMarppsFeaturedListProcess();
            }
        });
    }

    private startLoadMarppsFeaturedListProcess() {
        this.marppService.listMarppsFeatured()
            .subscribe(
                marpps => {
                    this.onLoadMarppsFeaturedListResponse(null, marpps);
                },
                err => {
                    this.onLoadMarppsFeaturedListResponse(err);
                }
            );
    }

    private onLoadMarppsFeaturedListResponse(err, result?) {
        if (err) {
            this.onLoadMarppsFeaturedListError(err);
        } else {
            this.onLoadMarppsFeaturedListOk(result);
        }
    }

    private onLoadMarppsFeaturedListOk(result) {
        this.setGridSelector(result);
    }

    private onLoadMarppsFeaturedListError(err) {
        const statusCode = err.status;
        let errorMessage = '';
        switch (statusCode) {
            case 400: {
                errorMessage = 'Invalid request for list featured Marpps, please try again.';
                break;
            }
            case 403: {
                errorMessage = 'Not authorized for list featured Marpps.';
                break;
            }
            default: {
                errorMessage = 'Request for featured Marpps failed, please try again.';
                break;
            }
        }
        this.snackBarNotificationService.error({title: 'List featured Marpps failed', description: errorMessage});
    }

    setGridSelector(marpps: IMarpp[]) {
        this.marppsMenuItems = marpps.map(marpp => {
            return {
                icon: marpp.imageURL,
                name: marpp.name,
                url: marpp.appURL
            };
        });

        // IMPORTANT FOR GRID TO CORRECTLY BE SHOWN, IT NEED EXACT MULTIPLES OF THREE
        const maxLoop = 3;
        let iteration = 0;
        while (this.marppsMenuItems.length % 3 !== 0 && iteration < maxLoop) {
            this.marppsMenuItems.push({});
            iteration++;
        }
    }

}
