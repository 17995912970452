
import {interval as observableInterval,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector   : 'fuse-countdown',
    templateUrl: './countdown.component.html',
    styleUrls  : ['./countdown.component.scss']
})
export class FuseCountdownComponent implements OnInit
{
    @Input() eventDate;
    countdown: any;

    constructor()
    {
        this.countdown = {
            days   : '',
            hours  : '',
            minutes: '',
            seconds: ''
        };
    }

    ngOnInit()
    {
        const currDate = Date.now();
        const eventDate = new Date(this.eventDate);

        let diff = Math.floor((currDate - eventDate.getTime()) / 1000);


        const countDown =
                  observableInterval(1000).pipe(
                      map(value => {
                          return diff = diff - 1;
                      }),
                      map(value => {
                          const now = Date.now();
                          const elapsedTime = (now - value * 1000) / 1000;
                          const days = Math.floor(elapsedTime / (60 * 60 * 24));
                          const hours = Math.floor(elapsedTime / (60 * 60)) % 24;
                          const minutes = Math.floor(elapsedTime / 60) % 60;
                          const seconds = Math.floor(elapsedTime % 60);

                          return {
                              days: days,
                              hours: hours,
                              minutes: minutes,
                              seconds: seconds
                          };
                      }), );

        countDown.subscribe(value => {
            this.countdown = value;
        });
    }
}
