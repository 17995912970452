import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseQuickPanelComponent } from 'app/main/content/quick-panel/quick-panel.component';
import {QuickPanelNotificationItemComponent} from './quick-panel-notification-item/quick-panel-notification-item.component';

@NgModule({
    declarations: [
        FuseQuickPanelComponent,
        QuickPanelNotificationItemComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,

        FuseSharedModule,
    ],
    exports: [
        FuseQuickPanelComponent
    ]
})
export class FuseQuickPanelModule
{
}
