import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {RequestCacheService} from './requestCache.service';

import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

const TTL = 100;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCacheService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let cachedResponse;
        if (this.isCacheable(req)) {
            cachedResponse = this.cache.get(this.getCacheKey(req));
        }

        return cachedResponse
            ? of(cachedResponse)
            : this.sendRequest(req, next);
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        if (this.isCacheable(req)) {
                            this.cache.set(this.getCacheKey(req), event, TTL);
                        }
                    }
                })
        );
    }

    getCacheKey(req: HttpRequest<any>) {
        return req.method + req.url;
    }

    isCacheable(req: HttpRequest<any>): boolean {
        return req.method === 'GET';
    }
}
