import {Injectable} from '@angular/core';


import {ApiClient} from './support/apiClient.service';
import {Observable, of} from 'rxjs';
import {IFeedItem} from '../../interface/IFeedItem';

@Injectable()
export class FeedItemService {

  basePath = '/feeditems';

  constructor(private apiClient: ApiClient) {
  }

  public listFeedItems (page: Number = 0): Observable<IFeedItem> {
      const path = `${this.basePath}?page=${page}&size=20`;
      return this.apiClient.get(path) as Observable<IFeedItem>;
  }

  public listFeedItemsByMe (page: Number = 0): Observable<IFeedItem> {
      const path = `${this.basePath}/author/me?page=${page}&size=20`;
      return this.apiClient.get(path) as Observable<IFeedItem>;
  }
}
