import {Component, OnInit} from '@angular/core';
import {AccountsService} from '../../../../services/api/accountsService.service';
import {SnackBarNotificationService} from '../../../../services/snack-bar-notification.service';
import {environment} from '../../../../../environments/environment';
import {PrincipalAuthService} from '../../../../services/principal-auth.service';
import {Routes} from '../../../../support/routes';
import {IdentityProfileService} from '../../../../services/api/identityProfileService.service';

@Component({
    selector: 'marco-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

    authUrl = environment.authServiceUrl;
    userData = { name: '', profileImage: '' };
    errorMessage = '';

    constructor(
        private accountsService: AccountsService,
        private principalAuthService: PrincipalAuthService,
        private identityProfileService: IdentityProfileService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {
    }

    ngOnInit() {
        this.loadUser();
    }

    private loadUser() {
        const sessionSubscription = this.principalAuthService.sessionSubscribe(activeSession => {
            if (activeSession && activeSession.user) {
                sessionSubscription.unsubscribe();
                this.userData.name = activeSession.user.identityProfile.displayName;
                this.identityProfileService.getImage(activeSession.user.marcoId)
                    .subscribe(
                        imageAsString => {
                            this.onDownloadingImageResponse(null, imageAsString);
                        },
                        err => {
                            this.onDownloadingImageResponse(err);
                        }
                    );
            }
        });
    }

    onDownloadingImageResponse(err, result?) {
        if (err) {
            this.onDownloadingImageResponseError(err);
        } else {
            this.onDownloadingImageOk(result);
        }
    }

    onDownloadingImageOk(result) {
        const profilePictureImg: HTMLElement = document.getElementById('profilePictureImg');
        const format = (result.format).split('; ')[1];
        profilePictureImg.setAttribute('src', 'data:image/' + format + ';base64,' + result.data);
    }

    onDownloadingImageResponseError(err) {
        console.log('Error downloading image = ');
        console.error(err);
        const statusCode = err.status;
        let errorMessage = '';
        switch (statusCode) {
            default: {
                errorMessage = 'Identity Profile image could not be downloaded';
                break;
            }
        }
        this.errorMessage = errorMessage;
    }

    logout() {
        this.accountsService.logout().subscribe(
            () => {
                this.snackBarNotificationService.success({title: 'Logged out', description: 'Your Marco session is closed'});
            }, err => {
                console.error(err);
            }
        );

        this.principalAuthService.logout();
        window.location.href = Routes.LOGOUT;
    }

}
