import {Injectable} from '@angular/core';
import {AES, enc} from 'crypto-js';

@Injectable()
export default class LocalManager {
    private static readonly activeSession: string = 'activeSession';

    static getActiveSession() {
        let activeSession = {};
        const encryptedActiveSession = localStorage.getItem(this.activeSession);
        if (encryptedActiveSession) {
            const decryptedActiveSession = AES.decrypt(encryptedActiveSession, 'accessToken').toString(enc.Utf8);
            if (decryptedActiveSession != '') {
                activeSession = JSON.parse(decryptedActiveSession);
            }
        }
        return activeSession;
    }

    static setActiveSession(activeSession: any = {}) {
        const activeSessionJson = JSON.stringify(activeSession);
        activeSession = AES.encrypt(activeSessionJson, 'accessToken').toString();
        localStorage.setItem(this.activeSession, activeSession);
    }

}
