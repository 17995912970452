import {Injectable} from '@angular/core';


import {ApiClient} from './support/apiClient.service';
import {Observable, of} from 'rxjs';
import {IIdentityLocal} from '../../interface/IIdentityLocal';
import {IIdentity} from '../../interface/IIdentity';

@Injectable()
export class AccountsService {

  basePath = '/accounts';

  constructor(private apiClient: ApiClient) {
  }

  public getMyself(): Observable<IIdentity> {
      const path = `${this.basePath}/identities/me`;
      return this.apiClient.get(path) as Observable<IIdentity>;
  }

  public getIdentity(marcoId: string): Observable<IIdentity> {
      const path = `${this.basePath}/identities/${marcoId}`;
      return this.apiClient.get(path) as Observable<IIdentity>;
  }

  public logout(): Observable<any> {
      const path = `${this.basePath}/accessToken`;
      return this.apiClient.delete(path);
  }

}
