<div class="notification-title secondary-text">
    Notifications
</div>

<mat-divider></mat-divider>

<mat-list>
    <div *ngFor="let notification of notifications">
        <marco-dashboard-notification-item [notification]="notification"></marco-dashboard-notification-item>
    </div>
</mat-list>

<button *ngIf="notifications.length > 0" (click)="loadNextPage()"
        mat-raised-button id="next-page-button" class="mat-accent mr-16">
    View more...
</button>
