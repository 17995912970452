import { Injectable } from '@angular/core';
import LocalManager from '../support/localManager';
import AuthHelper from '../support/authHelper';
import {AccountsService} from './api/accountsService.service';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class PrincipalAuthService {
  private activeSession: any = {};
  private sessionSubject = new BehaviorSubject<any>(undefined);

  constructor(private accountsService: AccountsService) {
      const existingAccessToken = AuthHelper.getSavedAccessToken();
      if (existingAccessToken) {
          this.login(existingAccessToken);
      }
  }

  isLoggedIn () {
    return this.getActiveSession() && !!this.getActiveSession().accessToken;
  }

  getActiveSession () {
    return this.activeSession;
  }

  sessionSubscribe (callback) {
      return this.sessionSubject.subscribe(callback);
  }

  login (accessToken) {
    this.activeSession = {
      accessToken: accessToken
    };
    this.saveActiveSession();
    this.loadLoggedSession();
  }

  logout () {
    this.activeSession = null;
    this.saveActiveSession();
  }

  loadLoggedSession () {
      console.log('Load logged session!');
      this.accountsService.getMyself()
          .subscribe(
              user => {
                console.log('USER LOGGED OBTAINED !');
                console.log(user);
                this.activeSession.user = user;
                this.sessionSubject.next(this.activeSession);
              },
              err => {
                console.log('USER LOGGED COULD NOT BE OBTAINED: ' + err.message);
                console.log(err);
                if (err.status && err.status >= 400 && err.status < 500) {
                    this.sessionSubject.next(null);
                }
              }
          );
  }

  private saveActiveSession () {
    LocalManager.setActiveSession(this.activeSession);
  }
}
