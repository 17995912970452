import {Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';

import {fuseConfig} from './fuse-config';

import {AppComponent} from './app.component';
import {FuseMainModule} from './main/main.module';

import {ApiClient} from './services/api/support/apiClient.service';
import {AccountsService} from './services/api/accountsService.service';
import {PrincipalAuthService} from './services/principal-auth.service';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackBarNotificationService} from './services/snack-bar-notification.service';

import {AuthStateRedirector} from './services/auth-state-redirector.service';
import {AuthGuard} from './main/guards/auth.guard';
import {FeedItemService} from './services/api/feedItemService.service';
import {CacheInterceptor} from './services/api/support/cache.interceptor';
import {RequestCacheService} from './services/api/support/requestCache.service';
import {ReplayableObservableCacheService} from './services/replayableObservableCache.service';
import {FeedSectionDataProvider} from './services/feedSectionDataProvider.service';
import {MarppService} from './services/api/marppService.service';
import {NotificationService} from './services/api/notificationService.service';
import {MatBadgeModule} from '@angular/material/badge';
import {IdentityProfileService} from './services/api/identityProfileService.service';


const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./main/content/pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'ignore',
        }),
        TranslateModule.forRoot(),

        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,

        MatSnackBarModule,
        MatBadgeModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        RequestCacheService,
        ReplayableObservableCacheService,
        AuthGuard,
        ApiClient,
        AccountsService,
        MarppService,
        FeedItemService,
        PrincipalAuthService,
        AuthStateRedirector,
        SnackBarNotificationService,
        FeedSectionDataProvider,
        NotificationService,
        IdentityProfileService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    static injector: Injector;

    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
