import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {INotification} from '../../../../interface/INotification';
import {FeedSectionDataProvider} from '../../../../services/feedSectionDataProvider.service';
import {IIdentity} from '../../../../interface/IIdentity';

@Component({
    selector: 'marco-dashboard-notification-item',
    templateUrl: './quick-panel-notification-item.component.html',
    styleUrls: ['./quick-panel-notification-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelNotificationItemComponent implements OnInit {

    @Input()
    notification: INotification;

    author: IIdentity;
    daysAgo: number;

    constructor(private feedSectionDataProvider: FeedSectionDataProvider) {
    }

    ngOnInit() {
        this.loadAuthorInfo();
        this.daysAgo = Math.floor((
            Date.now() - new Date(this.notification.dateCreated).getTime()) / (1000 * 60 * 60 * 24)
        );
    }

    private loadAuthorInfo() {
        this.feedSectionDataProvider.getAuthor(this.notification.notifierIdentity)
            .subscribe(
                authorUser => {
                    this.onLoadAuthorInfoResponse(null, authorUser);
                },
                err => {
                    this.onLoadAuthorInfoResponse(err);
                }
            );
    }

    onLoadAuthorInfoResponse(err, result?) {
        if (err) {
            this.onLoadAuthorInfoError(err);
        } else {
            this.onLoadAuthorInfoOk(result);
        }
    }


    onLoadAuthorInfoOk(result: IIdentity) {
        console.log('Author received for notification');
        console.log(result);
        this.author = result;
    }

    onLoadAuthorInfoError(err) {
        console.log('Error LoadAuthorInfo for notification for identity ' + this.notification.notifierIdentity);
        console.error(err);
        const statusCode = err.status;
        let errorMessage: string;
        switch (statusCode) {
            case 400: {
                errorMessage = 'Invalid request, please try again.';
                break;
            }
            case 403: {
                errorMessage = 'Not authorized.';
                break;
            }
            default: {
                errorMessage = 'Request could not be processed, please try again.';
                break;
            }
        }
    }

    getAuthorVisualName() {
        if (this.author) {
            return this.author.identityProfile.displayName;
        } else {
            return '';
        }
    }

}
