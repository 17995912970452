import {Injectable} from '@angular/core';


import {ApiClient} from './support/apiClient.service';
import {Observable, of} from 'rxjs';
import {INotification} from '../../interface/INotification';
import {INotificationIdentity} from '../../interface/INotificationIdentity';

@Injectable()
export class NotificationService {

  basePath = '/notifications';

  constructor(private apiClient: ApiClient) {
  }

  public getNotifications(type: string, page: Number): Observable<INotification[]> {
      const path = `${this.basePath}?type=${type}&page=${page}&size=8`;
      return this.apiClient.get(path) as Observable<INotification[]>;
  }

  public getNotificationsByStatus(type: string, status: string, page: Number): Observable<INotification[]> {
      const path = `${this.basePath}/status/${status}?type=${type}&page=${page}&size=50`;
      return this.apiClient.get(path) as Observable<INotification[]>;
  }

  public updateNotificationStatus(notificationIdentity: INotificationIdentity, status: string): Observable<INotificationIdentity> {
      const path = `${this.basePath}/update?status=${status}`;
      return this.apiClient.put(path, notificationIdentity) as Observable<INotificationIdentity>;
  }

  public countNotifications(type: string, status: string): Observable<Number> {
      const path = `${this.basePath}/count?type=${type}&status=${status}`;
      return this.apiClient.get(path) as Observable<Number>;
  }

}
