<div id="main-navigation" class="nav"
     [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-vertical-group *ngIf="item.type==='group'" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapse *ngIf="item.type==='collapse'" [item]="item"></fuse-nav-vertical-collapse>
            <fuse-nav-vertical-item *ngIf="item.type==='item'" [item]="item"></fuse-nav-vertical-item>

        </ng-container>

    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-horizontal-collapse *ngIf="item.type==='group'" [item]="item"></fuse-nav-horizontal-collapse>
            <fuse-nav-horizontal-collapse *ngIf="item.type==='collapse'" [item]="item"></fuse-nav-horizontal-collapse>
            <fuse-nav-horizontal-item *ngIf="item.type==='item'" [item]="item"></fuse-nav-horizontal-item>

        </ng-container>

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>
