import { Directive, Input, OnInit, HostListener, OnDestroy, HostBinding } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import {MediaObserver} from '@angular/flex-layout';

@Directive({
    selector: '[fuseMatSidenavHelper]'
})
export class FuseMatSidenavHelperDirective implements OnInit, OnDestroy
{
    matchMediaSubscription: Subscription;
    @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
    @Input('fuseMatSidenavHelper') id: string;
    @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string;

    constructor(
        private fuseMatSidenavService: FuseMatSidenavHelperService,
        private fuseMatchMedia: FuseMatchMediaService,
        private observableMedia: MediaObserver,
        private matSidenav: MatSidenav
    )
    {
    }

    ngOnInit() {
        this.fuseMatSidenavService.setSidenav(this.id, this.matSidenav);
        this.updateSidenavState();

        this.matchMediaSubscription = this.fuseMatchMedia.onMediaChange.subscribe(() => {
            this.updateSidenavState();
        });
    }

    private updateSidenavState() {
        const isLockedOpen = this.observableMedia.isActive(this.matIsLockedOpenBreakpoint + "");
        this.isLockedOpen = isLockedOpen;
        this.matSidenav.mode = isLockedOpen ? 'side' : 'over';
        this.matSidenav.toggle(isLockedOpen);
    }

    ngOnDestroy()
    {
        this.matchMediaSubscription.unsubscribe();
    }
}

@Directive({
    selector: '[fuseMatSidenavToggler]'
})
export class FuseMatSidenavTogglerDirective
{
    @Input('fuseMatSidenavToggler') id;

    constructor(private fuseMatSidenavService: FuseMatSidenavHelperService)
    {
    }

    @HostListener('click')
    onClick()
    {
        this.fuseMatSidenavService.getSidenav(this.id).toggle();
    }
}
