<mat-sidenav-container>

    <div id="fuse-main-content">
        <marco-dashboard-toolbar class="above" [ngClass]="fuseSettings.colorClasses.toolbar"></marco-dashboard-toolbar>

        <div id="wrapper">
            <div class="content-wrapper">
                <fuse-content></fuse-content>
            </div>
        </div>
    </div>

    <mat-sidenav position="end" fuseMatSidenavHelper="quick-panel">
        <fuse-quick-panel></fuse-quick-panel>
    </mat-sidenav>

</mat-sidenav-container>
