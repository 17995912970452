import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/main/content/toolbar/toolbar.component';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components/index';
import { MarppsComponent } from './marpps/marpps.component';
import {UserMenuComponent} from './user-menu/user-menu.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        MarppsComponent,
        UserMenuComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatBadgeModule,


        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule
    ],
    exports     : [
        ToolbarComponent,
        MarppsComponent,
        UserMenuComponent
    ]
})
export class ToolbarModule
{
}
