<button mat-button [matMenuTriggerFor]="userMenu"
        class="user-button">
    <div fxLayout="row" fxLayoutAlign="center center">
        <img id="profilePictureImg" alt='' class="avatar" src="{{userData.profileImage}}">
        <span class="username mr-12" fxHide fxShow.gt-sm>{{userData.name}}</span>
        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
    </div>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
    <a mat-menu-item [href]="authUrl">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
    </a>

    <a mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
    </a>
</mat-menu>
