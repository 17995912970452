import {Injectable} from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {environment} from '../../../../environments/environment';

import AuthHelper from '../../../support/authHelper';
import {Observable, of} from 'rxjs';

@Injectable()
export class ApiClient {

    private accessToken = '';

    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public post(path, data) {
        const url = this.baseUrl + path;
        return Observable.create((observer) => {
            this.http.post(url, data, this.getRequestOptions())
                .subscribe(
                    (result: any) => {
                        observer.next(result);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                        if (err.status === 401) {
                            window.location.reload();
                        }
                    }
                );
        });
    }

    public put(path, data) {
        const url = this.baseUrl + path;
        return Observable.create((observer) => {
            this.http.put(url, data, this.getRequestOptions())
                .subscribe(
                    (result: any) => {
                        observer.next(result);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                        if (err.status === 401) {
                            window.location.reload();
                        }
                    }
                );
        });
    }

    public get(path) {
        const url = this.baseUrl + path;
        return Observable.create((observer) => {
            this.http.get(url, this.getRequestOptions())
                .subscribe(
                    (result: any) => {
                        observer.next(result);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                    }
                );
        });
    }

    public delete(path) {
        const url = this.baseUrl + path;
        return Observable.create((observer) => {
            this.http.delete(url, this.getRequestOptions())
                .subscribe(
                    (result: any) => {
                        observer.next(result);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                        if (err.status === 401) {
                            window.location.reload();
                        }
                    }
                );
        });
    }

    private getRequestOptions() {
        const headers = {};
        headers['Content-Type'] = 'application/json';

        if (!this.accessToken) {
            this.accessToken = AuthHelper.getSavedAccessToken();
        }

        if (this.accessToken) {
            headers['Authorization'] = 'Bearer ' + this.accessToken;
        }

        return {headers: new HttpHeaders(headers)};
    }
}
