import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {NotificationService} from '../../../services/api/notificationService.service';
import {INotification} from '../../../interface/INotification';
import {FeedSectionDataProvider} from '../../../services/feedSectionDataProvider.service';
import {PrincipalAuthService} from '../../../services/principal-auth.service';
import {IIdentity} from '../../../interface/IIdentity';

@Component({
    selector     : 'fuse-quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseQuickPanelComponent implements OnInit {
    nextPage = 0;
    date: Date;
    notifications: INotification[] = [];

    constructor(private principalAuthService: PrincipalAuthService,
                private notificationService: NotificationService,
                private feedSectionDataProvider: FeedSectionDataProvider) {
        this.date = new Date();
    }

    ngOnInit() {
        this.loadUser();
    }

    private loadUser() {
        const sessionSubscription = this.principalAuthService.sessionSubscribe(activeSession => {
            if (activeSession && activeSession.user) {
                sessionSubscription.unsubscribe();
                this.loadNotifications();
            }
        });
    }

    private loadNotifications() {
        this.notificationService.getNotifications('DASHBOARD', this.nextPage)
            .subscribe((response: any) => {
                console.log(`Page ${this.nextPage} of dashboard notifications obtained:`);
                console.log(response);

                this.notifications = this.notifications.concat(response);
                this.markNotificationsAsRead(response);
            }, err => {
                console.log('Error on SetUnreadNotificationsCount ');
                console.error(err);
            });
    }

    private markNotificationsAsRead(notifications: INotification[]) {
        const notificationService = this.notificationService;
        notifications.forEach(function(notification) {
            notification.identities.forEach(function(notificationIdentity) {
                if (notificationIdentity.status === 'PENDING' || notificationIdentity.status === 'SENT') {
                    notificationService.updateNotificationStatus(notificationIdentity, 'READ')
                        .subscribe((response: any) => {
                            console.log(`Notification status changed from ${notificationIdentity.status} to READ`);
                            console.log(response);
                        });
                }
            });
        });
    }

    getNotifierInfo(notifierIdentity: string): string {
        let result = '';
        this.feedSectionDataProvider.getAuthor(notifierIdentity)
            .subscribe((author: IIdentity) => {
                result = author.identityProfile.displayName;
            },
            err => {
                result = '<' + notifierIdentity + '>';
            }
        );
        return result;
    }

    getDate(time: string): string {
        return new Date(time).toLocaleString();
    }

    loadNextPage() {
        this.nextPage++;
        this.loadNotifications();
    }

}
