<mat-toolbar class="p-0 mat-elevation-z1 mat-fuse-dark-600-bg">
    <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar"
                      color="accent" mode="indeterminate">
    </mat-progress-bar>

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="app ml-16">
                    <img class="app-icon" src="https://storage.googleapis.com/public-fb-app-marcocore-prod/marco-marpps/image_marpp_4.png">
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <span class="app-name">Dashboard</span>
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <marco-user-menu></marco-user-menu>

            <div class="toolbar-separator"></div>

            <marco-marpps></marco-marpps>

            <div class="toolbar-separator"></div>

            <button mat-icon-button class="quick-panel-toggle-button" fuseMatSidenavToggler="quick-panel"
                    (click)="showUnreadNotificationsCount = false">
                <mat-icon matBadge="{{unreadNotificationsCount}}" matBadgeColor="accent"
                          [matBadgeHidden]="!showUnreadNotificationsCount" class="icon">
                    notifications
                </mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
