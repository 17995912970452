import { Injectable } from '@angular/core';
import { Observable , ReplaySubject} from 'rxjs';

@Injectable()
export class ReplayableObservableCacheService {
    private cache = new Map<string, [Date, Observable<any>]>();

    get(key): Observable<any> {
        const tuple = this.cache.get(key);
        if (!tuple) {
            return null;
        }

        const expires = tuple[0];
        const observable = tuple[1];

        // Don't observe expired keys
        const now = new Date();
        if (expires && expires.getTime() < now.getTime()) {
            this.cache.delete(key);
            return null;
        }

        return observable;
    }

    set(key, observable: Observable<any>, ttl = null) {
        const replayableObservale = this.replayable(observable);
        if (ttl) {
            const expires = new Date();
            expires.setSeconds(expires.getSeconds() + ttl);
            this.cache.set(key, [expires, replayableObservale]);
        } else {
            this.cache.set(key, [null, replayableObservale]);
        }
        return replayableObservale;
    }

    private replayable<T>(o: Observable<T>): Observable<T> {
        const replay = new ReplaySubject<T>(1);
        o.subscribe(
            x => replay.next(x),
            x => replay.error(x),
            () => replay.complete()
        );
        return replay.asObservable();
    }
}
