<div class="fuse-search-bar" [ngClass]="{'expanded':!collapsed}" fxFlex="0 1 auto">
    <div [ngClass]="toolbarColor" fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <label for="fuse-search-bar-input">
            <button mat-icon-button class="fuse-search-bar-expander" aria-label="Expand Search Bar" (click)="expand()"
                    *ngIf="collapsed">
                <mat-icon class="s-24">search</mat-icon>
            </button>
            <!--<span class="fuse-search-bar-loader" fxLayout="row" fxLayoutAlign="center center" *ngIf="!collapsed">
                <mat-progress-spinner color="mat-accent" mode="indeterminate"></mat-progress-spinner>
            </span>-->
        </label>

        <input id="fuse-search-bar-input" class="ml-24" type="text" placeholder="Search" (input)="search($event)" fxFlex>

        <button mat-icon-button class="fuse-search-bar-collapser mat-icon-button" (click)="collapse()"
                aria-label="Collapse Search Bar">
            <mat-icon class="s-24">close</mat-icon>
        </button>

    </div>
</div>