import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarNotificationService {

  constructor(public snackBar: MatSnackBar) {
  }

  error ({title, description}) {
    const type = 'error';
    this.showNotification({type, title, description});
  }

  success ({title, description}) {
    const type = 'success';
    this.showNotification({type, title, description});
  }

  private showNotification ({type, title, description}) {
      this.snackBar.open(description, null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
      });
  }
}
