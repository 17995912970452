import {Router} from '@angular/router';
import {PrincipalAuthService} from './principal-auth.service';
import {Injectable} from '@angular/core';
import {Routes} from '../support/routes';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthStateRedirector {

  constructor(private router: Router,
              private principalAuthService: PrincipalAuthService) {
  }

  redirectToLoginIfNotLoggedIn(returnPath: string): Promise<boolean> {
      if (!this.principalAuthService.isLoggedIn()) {
          return this.redirectToAuth(returnPath);
      } else {
          return this.validateUserLoggedIn(returnPath);
      }
  }

  private redirectToAuth(returnPath: string): Promise<boolean> {
      return new Promise((resolve, reject) => {
          this.processLogout(returnPath);
          resolve(false);
      });
  }

  private validateUserLoggedIn(returnPath): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
          this.principalAuthService.sessionSubscribe(activeSession => {
              if (activeSession === undefined) {
                  return;
              }

              let redirected = false;
              if (!activeSession) {
                  this.processLogout(returnPath);
                  redirected = true;
              }

              resolve(!redirected);
          });
      });
  }

    private processLogout(returnPath) {
        this.principalAuthService.logout();
        const loginUrl = this.buildLoginUrl(returnPath);
        window.location.href = loginUrl;
    }

    private buildLoginUrl(nextUrl) {
        const returnUrl = this.buildReturnUrl(nextUrl);
        return this.buildAccountsAuthUrl(returnUrl);
    }

    private buildReturnUrl(nextUrl) {
        const currentUrl = new URL(window.location.href);
        currentUrl.pathname = Routes.SIGN_IN;
        currentUrl.searchParams.delete("search");
        currentUrl.searchParams.set("next", nextUrl);
        return currentUrl.toString();
    }

    private buildAccountsAuthUrl(returnUrl) {
        const queryParams = new URLSearchParams({
            scope: 'FULL_ACCESS',
            clientId: environment.clientId,
            redirect: returnUrl
        });

        const authUrl = new URL(environment.authServiceUrl + '/auth/access');
        authUrl.search = queryParams.toString();

        return authUrl.toString();
    }
}
