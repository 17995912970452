import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {PrincipalAuthService} from '../../../services/principal-auth.service';
import {SnackBarNotificationService} from '../../../services/snack-bar-notification.service';
import {NotificationService} from '../../../services/api/notificationService.service';

@Component({
    selector   : 'marco-dashboard-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls  : ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit
{
    showLoadingBar: boolean;

    showUnreadNotificationsCount = false;
    unreadNotificationsCount: Number = 0;

    constructor(
        private router: Router,
        private principalAuthService: PrincipalAuthService,
        private snackBarNotificationService: SnackBarNotificationService,
        private notificationService: NotificationService,
    )
    {
        router.events.subscribe(
            (event) => {
                if ( event instanceof NavigationStart )
                {
                    this.showLoadingBar = true;
                }
                if ( event instanceof NavigationEnd )
                {
                    this.showLoadingBar = false;
                }
            });
    }

    ngOnInit() {
        this.loadUser();
    }

    private loadUser() {
        const sessionSubscription = this.principalAuthService.sessionSubscribe(activeSession => {
            if (activeSession && activeSession.user) {
                sessionSubscription.unsubscribe();
                this.setUnreadNotificationsCount();
            }
        });
    }

    private setUnreadNotificationsCount() {
        this.notificationService.countNotifications('DASHBOARD', 'PENDING')
            .subscribe((response: Number) => {
                    this.unreadNotificationsCount = response;
                    this.showUnreadNotificationsCount = (this.unreadNotificationsCount > 0);
                }
            );
    }

}
