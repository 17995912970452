<button mat-icon-button
        class="mat-icon-button quick-panel-toggle-button"
        [matMenuTriggerFor]="appsMenu">
    <mat-icon class="icon">apps</mat-icon>
</button>

<mat-menu #appsMenu="matMenu" [overlapTrigger]="false">
    <div fxLayout="row wrap" fxLayoutAlign="space-around start" fxFlexAlign="start" class="toolbar-marpps-menu">
        <div *ngFor="let marppMenuItem of marppsMenuItems" fxLayout="column" fxLayoutAlign="start center"
             class="marpp-shortcut-box">
            <a *ngIf="marppMenuItem.url" href="{{ marppMenuItem.url }}" target="_blank">
                <img *ngIf="marppMenuItem.icon" class="apps-menu-app-icon" src="{{marppMenuItem.icon}}"
                     title="{{marppMenuItem.name}}">
                <p *ngIf="marppMenuItem.name">{{marppMenuItem.name.replace('MARCO ', '')}}</p>
            </a>
        </div>
    </div>
</mat-menu>

