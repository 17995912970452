import { Injectable } from '@angular/core';
import {ReplayableObservableCacheService} from './replayableObservableCache.service';
import {Observable, of} from 'rxjs';
import {IIdentity} from '../interface/IIdentity';
import {AccountsService} from './api/accountsService.service';

@Injectable()
export class FeedSectionDataProvider {

    private readonly TTL = 120;

    constructor(
        private replayableObservableCacheService: ReplayableObservableCacheService,
        private accountsService: AccountsService
    ) {}

    public getAuthor(marcoId: string): Observable<IIdentity> {
        const cacheKey = `get-author-[${marcoId}]`;

        const observableCached = this.replayableObservableCacheService.get(cacheKey);

        if (observableCached) {
            console.log('Observable for get author was already cached. Returning it');
            return observableCached;
        } else {
            console.log('Observable for get author NOT cached. Calling a new one');
            const observable = this.accountsService.getIdentity(marcoId);
            return this.replayableObservableCacheService.set(cacheKey, observable, this.TTL);
        }
    }

}
