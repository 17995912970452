import {Injectable} from '@angular/core';


import {ApiClient} from './support/apiClient.service';
import {Observable, of} from 'rxjs';
import {IMarpp} from '../../interface/IMarpp';

@Injectable()
export class MarppService {

  basePath = '/marpps';

  constructor(private apiClient: ApiClient) {
  }

  public listMarppsFeatured (): Observable<IMarpp> {
      const path = `${this.basePath}/featured`;
      return this.apiClient.get(path) as Observable<IMarpp>;
  }

  public listMarppsRecentlyUsed (): Observable<IMarpp> {
      const path = `${this.basePath}/recent`;
      return this.apiClient.get(path) as Observable<IMarpp>;
  }
}
