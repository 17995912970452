import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthStateRedirector} from '../../services/auth-state-redirector.service';
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private authStateRedirector: AuthStateRedirector
    ) { }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        if (environment.production === false) {
            return true;
        }

        console.log('Auth guard: canActivate');
        return this.validateLoginAndRedirectIfNecessary(route, routerState);
    }

    canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        if (environment.production === false) {
            return true;
        }

        console.log('Auth guard: canActivateChild');
        return this.validateLoginAndRedirectIfNecessary(route, routerState);
    }

    private validateLoginAndRedirectIfNecessary (route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<boolean> {
        return this.authStateRedirector.redirectToLoginIfNotLoggedIn(routerState.url);
    }
}
