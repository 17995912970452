import { MediaObserver } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FuseMatchMediaService
{
    activeMediaQuery: string;
    onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private mediaObserver: MediaObserver) {
        this.activeMediaQuery = '';

        this.mediaObserver.asObservable().subscribe((change) => {
            change.forEach((item) => {
                if (this.activeMediaQuery !== item.mqAlias) {
                    this.activeMediaQuery = item.mqAlias;
                    this.onMediaChange.next(item.mqAlias);
                }
            });
        });
    }
}
