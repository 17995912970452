import {Injectable} from '@angular/core';


import {ApiClient} from './support/apiClient.service';
import {Observable, of} from 'rxjs';

@Injectable()
export class IdentityProfileService {

  basePath = '/identityProfile';

  constructor(private apiClient: ApiClient) {
  }

  public getImage(marcoId: String): Observable<any> {
      const path = `${this.basePath}/image/${marcoId}`;
      return this.apiClient.get(path);
  }

}
